import React from "react";
import SignInPage from "./pages/SignInPage";
import History from "./pages/HistoryPage";
import Terminals from "./pages/Terminals";
import TerminalDetails from "./pages/TerminalDetails";
import Merchants from "./pages/Merchants";
import Transactions from "./pages/Transactions";
import Commissions from "./pages/Commissions";
import Reports from "./pages/Reports";
import TransactionsDetails from "./pages/TransactionsDetails";
import MerchantDetails from "./pages/MerchantDetails";
import Disputs from "./pages/Disputs";
import DisputDetails from "./pages/DisputDetails";
import DisputeInfo from "./pages/DisputDetails/DisputeInfo";
import Stores from "./pages/Stores";
import StoreDetails from "./pages/StoreDetails";
import Sellers from "./pages/Sellers";
import SellerDetails from "./pages/SellerDetails";
import MerchantFlow from "./pages/MerchantFlow";
import Users from "./pages/Users";
import UsersDetails from "./pages/UsersDetails";

import { Route, Switch, Redirect } from "react-router-dom";

import "./App.scss";

const App = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignInPage} />
      <Route path="/app/terminals" exact component={Terminals} />
      <Route
        path="/app/terminals/:terminalId"
        exact
        component={TerminalDetails}
      />
      <Route path="/app/merchants" exact component={Merchants} />
      <Route
        path="/app/merchants/:merchantId"
        exact
        component={MerchantDetails}
      />

      <Route path="/app/users" exact component={Users} />
      <Route
        path="/app/users/:userId"
        exact
        component={UsersDetails}
      />

      <Route path="/app/stores" exact component={Stores} />
      <Route path="/app/stores/:storeId" exact component={StoreDetails} />
      <Route path="/app/sellers" exact component={Sellers} />
      <Route path="/app/sellers/:sellerId" exact component={SellerDetails} />
      <Route path="/app/history" exact component={History} />
      <Route path="/app/flow" exact component={MerchantFlow} />
      <Route
        path="/app/transactions/:txId"
        exact
        component={TransactionsDetails}
      />
      <Route path="/app/transactions" exact component={Transactions} />
      <Route path="/app/commissions" exact component={Commissions} />
      <Route path="/app/reports" exact component={Reports} />

      <Route path="/app/disputs" exact component={Disputs} />
      <Route
        path="/app/disputs/:disputId"
        exact
        render={({ match }) =>
          match.params.disputId === "new" ? (
            <DisputDetails />
          ) : (
            <DisputeInfo disputeId={match.params.disputId} />
          )
        }
      />

      <Redirect to="/app/merchants" />
    </Switch>
  );
};

export default App;
