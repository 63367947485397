// token related
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAIL = "FETCH_TOKEN_FAIL";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const CHANGE_LOGIN = "CHANGE_PHONE";
export const TOGGLE_REMEMBER_SESSION = "TOGGLE_REMEMBER_SESSION";
export const CHANGE_PASSWORD = "CHANGE_CODE";

// app
export const AUTH_FETCH = "AUTH_FETCH"
export const AUTH_START = "AUTH_START"
export const AUTH_STOP = "AUTH_STOP"
export const AUTH_FINISH = "AUTH_FINISH"
export const SET_AUTH_DATA = "SET_AUTH_DATA"
export const SET_TOKEN = "SET_TOKEN"
export const LOGOUT_START = "LOGOUT_START"
export const LOGOUT_FINISH = "LOGOUT_FINISH"
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"
export const SAVE_TOKEN = "SAVE_TOKEN"

export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO"
export const UPLOAD_COMPANY_INFO = "UPLOAD_COMPANY_INFO"
export const SHOW_SIDE_DRAWER = "SHOW_SIDE_DRAWER"
export const SAVE_SETTINGS = "SAVE_SETTINGS"
export const SAVE_MARKET_HOURS = "SAVE_MARKET_HOURS"
export const ON_START_MARKET = "ON_START_MARKET"
export const SET_EXPIRED_TIME = "SET_EXPIRED_TIME"
export const SET_PAGE_RELOADED = "SET_PAGE_RELOADED"
export const SET_TOKEN_IN_HEADERS = "SET_TOKEN_IN_HEADERS"
export const CHECK_IF_TOKEN_EXPIRED = "CHECK_IF_TOKEN_EXPIRED"
export const ON_FIRE_REFRESH_TOKEN = "ON_FIRE_REFRESH_TOKEN"
export const SET_MODE_STATUS = "SET_MODE_STATUS"
export const SET_PERIOD = "SET_PERIOD"
export const SET_PAGE_SIZE = "SET_PAGE_SIZE" 
export const SET_CHANGE_DATE = "SET_CHANGE_DATE"  
export const SET_PREV_NOTIF_COUNT = "SET_PREV_NOTIF_COUNT" 
export const SET_NOTIF_COUNT = "SET_NOTIF_COUNT"
export const SAVE_FLOW_DATA = "SAVE_FLOW_DATA"
export const RESET_FLOW_DATA = "RESET_FLOW_DATA"
export const FLOW_DATA_ALL_STEPS_VISITED = "FLOW_DATA_ALL_STEPS_VISITED"
export const SET_VISITED_STEP = "SET_VISITED_STEP"