import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import NavigationComponent from "../../components/NavigationComponent";
import TopNavBarComponent from "../../components/TopNavBarComponent";
import axios from "../../../api/services/Api";
import { navigation } from "../../../constants/data";
import { getAuthData } from "../../../api/selectors/authSelector";
import { enqueueSnackbar } from "notistack";
import {
  removeToken,
  removeUserData,
  removeExpiredTime,
  removeRefreshToken,
  removeMaintenanceModeStatus,
} from "../../../api/services/Auth";
import {
  logoutStart,
  logoutFinish,
  setToken,
  setExpiredTime,
  signIn,
  setTokenInHeaders,
} from "../../../api/actionCreators/authActionCreator";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { IAppMainLayoutProps } from "./types";
import classNames from "classnames";
import { getRefreshToken } from "../../../api/selectors/tokenSelector";
import { useTranslation } from "react-i18next";

import styles from "./app-main-layout.module.scss";

function AppMainLayout({
  navBarTitle,
  children,
  noBackground,
  className,
  breadcrumbs,
  flowMode = false,
}: IAppMainLayoutProps) {

  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refreshToken = useSelector(getRefreshToken);
  const data = useSelector(getAuthData);
  const authData = JSON.parse(data);

  const pathname = window.location.pathname;

  useEffect(() => {
    const hasMatchingRoles = (menuRoles, userRoles = []) => {
      return menuRoles.some(role => userRoles.includes(role));
    };

    const filteredNavigation = navigation.filter(item =>
      hasMatchingRoles(item.forRole, authData?.roles)
    );

    const rolesForCurPage = navigation.find(item => item.url === pathname || pathname.includes(item.url));
    const isAccess = rolesForCurPage?.forRole.some(role => authData?.roles.includes(role));
    !isAccess && enqueueSnackbar(t("You_do_not_have_access"), { variant: "error" });
    !isAccess && setRedirectUrl(filteredNavigation[0]?.url)

  }, [pathname])

  useEffect(() => {
    redirectUrl !== "" && setRedirectUrl("")
  }, [redirectUrl])

  const clearData = () => {
    removeExpiredTime();
    removeRefreshToken();
    removeToken();
    removeUserData();
    removeMaintenanceModeStatus();

    dispatch(logoutFinish());
    dispatch(setToken({ accessToken: "", refreshToken: "", expiresIn: "" }));
    dispatch(setExpiredTime(null));
    dispatch(signIn(null));

    dispatch(setTokenInHeaders(false));
    setRedirect(true);
    //window.location.reload()
  };

  const onExit = () => {
    dispatch(logoutStart());
    const params = {
      refreshToken,
    };
    axios.post("/sap/api/v1/auth/logout", { ...params }).then(() => {
      clearData();
    });
  };

  return (
    <>
      {redirect && <Redirect to="/" />}
      {redirectUrl && <Redirect to={redirectUrl} />}
      <div
        className={classNames({
          [`${styles.main_section}`]: true,
          [`${className}`]: true,
          [`${styles.flow_mode_container_styles}`]: flowMode,
        })}
      >
        <div
          className={classNames({
            [`${styles.main_grid}`]: true,
            [`${styles.flow_mode_styles}`]: flowMode,
          })}
        >
          {!flowMode && <NavigationComponent />}
          <div>
            {/* {mode && <WarningBanner />} */}
            {!flowMode && <TopNavBarComponent {...{ breadcrumbs, navBarTitle, onExit }} />}
            <Box>
              <Paper
                elevation={1}
                className={classNames({
                  [`${styles.w100}`]: true,
                  [`${styles.background_none}`]: noBackground,
                })}
              >
                {children}
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppMainLayout;
