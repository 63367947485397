import moment from "moment"

export const LAST_MONTH = 1;
export const LAST_THREE_MONTHS = 2;
export const QUARTER = 3;
export const CUSTOM_PERIOD = 4;
export const LAST_HOUR = 5;
export const LAST_DAY = 6;

export const pages = {
    TERMINAL_PAGE: "terminalPage",
    MERCHANT_PAGE: "merchantPage",
    TRANSACTION_PAGE: "transactionPage",
    DISPUT_PAGE: "disputPage",
    SELLERS_PAGE: "sellersPage",
    USERS_PAGE: "usersPage"
}

export const filterData = [
    { name: "all", showInDropDown: false },
    { name: "new", showInDropDown: true },
    { name: "invoiced", showInDropDown: true },
    { name: "overdue", showInDropDown: true },
    { name: "payed", showInDropDown: true },
    { name: "canceled", showInDropDown: true },
]
// const LOGO_TYPES = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'];
const LOGO_MAX_SIZE = 10 * 1024 * 1024; // 10 Mb

export const isImageValid = (file, t, enqueueSnackbar) => {

    if (!file || !t) {
        return false;
    }

    // const fileType = file.type;
    const fileSize = file.size;

    // if (!LOGO_TYPES.includes(fileType)) {
    //     alert(t("Invalid_file_type"));
    //     return false;
    // }

    if (fileSize > LOGO_MAX_SIZE) {
        enqueueSnackbar(t("Max_logo_size"), { variant: "error" });
        return false;
    }

    return true;
};

export const filters = ["all", "active", "completed", "deleted", "in_process", "expired", "error"]

export const filtersTransactions = ["all", "success", "error", "partial_revert", "revert"]

export const filtersDisputes = ["all", "discussion", "approved", "canceled", "closed"]

export const filtersSellers = ["all", "online", "offline", "suspended"]

export const FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const generateToken = () => {
    let token = "";
    const possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

    for (var i = 0; i < 50; i++)
        token += possible.charAt(Math.floor(Math.random() * possible.length));

    return token;
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

// format as ### ###
export const numberFormatter = (number) => Number(number).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$& ');

export const checkDisabledButton = (uploadedData, customerInfo) => {
    const disabled =
        uploadedData.commissionInfo.buyCommission.toString() === customerInfo.commision.values.buyCommission.toString() &&
        uploadedData.commissionInfo.sellCommission.toString() === customerInfo.commision.values.sellCommission.toString() &&
        uploadedData.companyInfo.comment === customerInfo.general.values.description &&
        uploadedData.companyInfo.companyName === customerInfo.general.values.companyName &&
        uploadedData.companyInfo.idno === customerInfo.general.values.idno &&
        uploadedData.directorInfo.directorFullName === customerInfo.users.values.directorNameSurname &&
        uploadedData.directorInfo.idnp === customerInfo.users.values.directorIdnp &&
        uploadedData.directorInfo.phone.toString() === customerInfo.users.values.directorPhone.toString();

    return disabled
}


export const checkIfTokenExpired = (expiredTime) => {
    const now = moment()
    const secDiff = now.diff(expiredTime, "seconds");

    const res = (secDiff * -1) < 20 ? true : false
    return res
}

export const isEmailValid = (email) => {
    /* eslint-disable */
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
};

export const VALIDATION = {
    SIGN_IN_FORM: ["login", "password"],
    ADD_MERCHANT_FORM_COMMON: ["minCommission", "merchantName", "merchantIdno", "countryOfResidence", "creditorName", "creditorAccountIban", "creditorAccount", "commissionRate"],
    EDIT_MERCHANT_FORM_COMMON: ["merchantName", "merchantIdno", "countryOfResidence", "creditorName", "creditorAccountIban", "creditorAccount"],
    ADD_MERCHANT_FORM_CAP_USER: ["phone", "email", "fullName", "idnp"],
    ADD_TERMINAL_FORM: ["terminalId", "mccCode", "merchantId"],
    ADD_STORE_FORM: ["storeName", "storeAddress", "merchantId"],
    EDIT_SELLER_FORM: ["fullName", "storeId", "phoneNumber", "email", "username"],
    ADD_SELLER_FORM: ["fullName", "storeId", "merchantId", "phoneNumber", "email", "username"],
    ADD_EDIT_USER: ["email", "fullName", "username", "roles"],
    ADD_DISPUT_FORM: ["title", "categoryId", "reasonId", "disputeDescription", "merchantId", "swiftMessageId", "swiftMessageType", "amount"],
}

export const getModalText = (state, actionRowId, t) => {
    let text

    switch (state) {
        case "change":
            text = {
                title: t("Confirm_password_change"),
                description: t("Confirm_password_change_description"),
                btnLabel: t("Change"),
                method: "post",
                apiUrl: ``,
                url: "",
                locale: "",
                localeOther: "",
                postFix: false
            }
            break;

        case "restore":
            text = {
                title: t("Confirm_user_restore"),
                description: t("Confirm_user_restore_description"),
                btnLabel: t("Restore"),
                method: "post",
                apiUrl: `/cap/api/v1/seller/${actionRowId}/restore`,
                url: "/app/sellers/",
                locale: "Notification_restore_user",
                localeOther: "",
                postFix: false
            }
            break;
        case "delete":
            text = {
                title: t("Confirm_seller_delete"),
                description: t("Confirm_seller_delete_description"),
                btnLabel: t("Delete"),
                apiUrl: `/cap/api/v1/seller/${actionRowId}`,
                method: "delete",
                url: "/app/sellers/",
                locale: "Notification_delete_user",
                localeOther: "",
                postFix: false
            }
            break;

        case "suspend":
            text = {
                title: t("Confirm_user_suspend"),
                description: t("Confirm_user_suspend_description"),
                btnLabel: t("Suspend"),
                apiUrl: `/cap/api/v1/seller/${actionRowId}/suspend`,
                method: "post",
                url: "/app/sellers/",
                locale: "Notification_suspend_user",
                localeOther: "",
                postFix: false
            }
            break;

        case "assign":
            text = {
                title: t("Confirm_user_assign"),
                description: t("Confirm_user_assign_description"),
                btnLabel: t("Assign"),
                apiUrl: `/cap/api/v1/pos/${actionRowId}/store/assign`,
                method: "post",
                url: "",
                locale: "Notification_assign_terminal",
                localeOther: "Notification_assign_terminal_other",
                postFix: true
            }
            break;
        case "unassign":
            text = {
                title: t("Confirm_user_unassign"),
                description: t("Confirm_user_unassign_description"),
                btnLabel: t("Confirm"),
                apiUrl: `/cap/api/v1/pos/${actionRowId}/store/unassign`,
                method: "post",
                url: "",
                locale: "Notification_unassign_terminal",
                localeOther: "Notification_unassign_terminal_other",
                postFix: false
            }
            break;
        default:

            text = {
                title: t("Transfer_seller"),
                description: "",
                btnLabel: t("Save"),
                apiUrl: `/cap/api/v1/seller/${actionRowId}/store/transfer`,
                method: "post",
                url: "/app/sellers/",
                locale: "Notification_confirm_user_transfer",
                localeOther: "Notification_other_transfer", 
                postFix: false
            }
            break;
    }

    return text
}