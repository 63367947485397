import {
    CHANGE_LANGUAGE,
    SET_CUSTOMER_INFO,
    UPLOAD_COMPANY_INFO,
    SHOW_SIDE_DRAWER,
    SAVE_SETTINGS,
    SAVE_MARKET_HOURS,
    ON_START_MARKET,
    SET_PERIOD,
    SET_PAGE_SIZE,
    SET_CHANGE_DATE,
    SET_PREV_NOTIF_COUNT,
    SET_NOTIF_COUNT,
    SAVE_FLOW_DATA,
    RESET_FLOW_DATA,
    FLOW_DATA_ALL_STEPS_VISITED,
    SET_VISITED_STEP,
} from '../actions';
import i18n from "../../i18n";
import { storePrefLang } from "../services/Prefs";
import moment from "moment";

export const setCustomerInfo = (stateName, value, block) => {
    return { type: SET_CUSTOMER_INFO, payload: { stateName, value, block } };
}

export const setPrevNotificationCount = (payload) => {
    return { type: SET_PREV_NOTIF_COUNT, payload };
}

export const setVisitedStep = (payload) => {
    return { type: SET_VISITED_STEP, payload };
}

export const setNotificationCount = (payload) => {
    return { type: SET_NOTIF_COUNT, payload };
}

export const saveFlowData = (stateName, stateData, deletedItemId = null) => {
    return { type: SAVE_FLOW_DATA, payload: { stateName, stateData, deletedItemId } };
}
export const resetFlowData = () => {
    return { type: RESET_FLOW_DATA };
}

export const flowDataAllStepsVisited = () => {
    return { type: FLOW_DATA_ALL_STEPS_VISITED };
}

export const uploadCompanyDetails = (payload) => {
    return { type: UPLOAD_COMPANY_INFO, payload };
}

export const saveMarketHours = (time) => {
    return { type: SAVE_MARKET_HOURS, payload: { ...time } };
}

export const setChangeDate = (dateKey, dateValue) => {
    return { type: SET_CHANGE_DATE, payload: { dateKey, dateValue } };
}

export const setPageSize = (page, size) => {
    return { type: SET_PAGE_SIZE, payload: { page, size } };
}


export const setPeriod = (value) => {
    return { type: SET_PERIOD, payload: value };
}

export const onStartMarket = (state) => {
    return { type: ON_START_MARKET, payload: state };
}

export const onShowSideDrawer = () => {
    return { type: SHOW_SIDE_DRAWER };
}

export const saveSettings = (payload) => {
    return { type: SAVE_SETTINGS, payload };
}

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return { type: CHANGE_LANGUAGE, payload: lng };
}
