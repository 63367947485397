import React, { useState, useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Logo } from "../../../assets/icons/logo";

import { INavigationComponentProps } from "./types";
import classNames from "classnames";
import { navigation } from "../../../constants/data";
import styles from "./navigation.module.scss";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAuthData } from "../../../api/selectors/authSelector";
// import TextField from "@mui/material/TextField";
// import { ExitIcon } from "../../../svg-icons/icon-exit";
import { useTranslation } from "react-i18next";
// import { getIsAuthFetching } from "../../../api/selectors/authSelector";
import { getNotificationCount, getPrevNotificationCount } from "../../../api/selectors/mainSelector";
import {
  setPrevNotificationCount,
  setNotificationCount,
} from "../../../api/actionCreators/mainActionCreator";

import { enqueueSnackbar } from "notistack";
import AirIcon from '@mui/icons-material/Air';

function NavigationComponent({ className }: INavigationComponentProps) {
  const { t } = useTranslation();
  const data = useSelector(getAuthData);
  const authData = JSON.parse(data);

  const notificationCount = useSelector(getNotificationCount);
  const prevNotificationCount = useSelector(getPrevNotificationCount);
  const dispatch = useDispatch();

  const [time, setTime] = useState(0);
  const timerRef = useRef(null);

  const pathname = window.location.pathname;

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1); // Обновляем время каждые 10 миллисекунд
    }, 1000);

    return () => clearInterval(timerRef.current)
  }, [])


  const fetchDisputeCount = async () => {
    try {
      const source = axios.CancelToken.source();

      const response = await axios.get("/sap/api/v1/dispute/bank/count", {
        cancelToken: source.token,
      });

      const newNotificationCount = response.data;

      if (newNotificationCount !== prevNotificationCount) {
        dispatch(setPrevNotificationCount(notificationCount));
        dispatch(setNotificationCount(newNotificationCount));
      }
      setTime(0);
    } catch (error) {
      if (axios.isCancel(error)) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    }
  };

  useEffect(() => {
    time > 10 && fetchDisputeCount();
  }, [time]);

  const renderMenu = () => {

    const hasMatchingRoles = (menuRoles, userRoles = []) => {
      return menuRoles.some(role => userRoles.includes(role));
    };

    const filteredNavigation = navigation.filter(item =>
      hasMatchingRoles(item.forRole, authData?.roles)
    );

    const content = filteredNavigation.map(({ local, url, icon, showNotification }, index) => {
      const selectedMenu = pathname.includes(url);
      return (
        <Link
          underline="none"
          className={classNames({
            [`${styles.nav_link_wrapper}`]: true,
            [`${styles.active_nav_menu}`]: selectedMenu,
          })}
          to={`${url}`}
          key={index}
        >
          <span className={styles.nav_icon}>{icon}</span>
          <div className={styles.nav_cta_link}>
            {t(local)}
            {showNotification && (prevNotificationCount > 0 || notificationCount > 0) && (
              <span className={styles.notification_circle}>
                {notificationCount !== 0
                  ? `+${notificationCount}`
                  : prevNotificationCount !== 0 || prevNotificationCount > 0
                    ? `+${prevNotificationCount}`
                    : ""}{" "}
              </span>
            )}
          </div>
        </Link>
      );
    });

    return content;
  };

  return (
    <div
      className={classNames({
        [`${styles.nav_wrapper}`]: true,
        [className]: true,
      })}
    >
      {/* {renderExitModal()} */}
      <div className={styles.nav_logo}>
        <Logo />
      </div>

      <div className={styles.divider} />

      <div>
        <div className={styles.nav_menu}>
          {renderMenu()}
        </div>
      </div>

      <div className={styles.navbar_footer}>
        <div className={styles.block_item}>
          <div className={styles.support}>
            {" "}
            {t("Support")}: <a href="tel:+37322555544">+373 22 555 544</a>
          </div>
          <div className={styles.copyright}>
            {" "}
            {t("Build_by")}{" "}
            <a href="https://finergy.md/" target="_blank" rel="noreferrer">
              Finergy Tech.
            </a>
            <br /> {t("All_rights_reserved")}.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationComponent;
