/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Redirect, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SaveIcon } from "finergy-kit";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import Button from "../../infrastructure/components/Button";
import { VALIDATION, isEmailValid } from "../../constants";
import FormControl from "@mui/material/FormControl";
import { ResetIcon } from "../../svg-icons/icon-reset";
import { DeleteIcon } from "../../svg-icons/icon-delete";
import { apiRequest } from "../../api/services/Api";
import classNames from "classnames";
import { Checkbox, FormControlLabel } from "@mui/material";

import styles from "./users-details.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UsersDetails = () => {
  const { userId } = useParams();

  const { t } = useTranslation();
  const defaultUserInfo = {
    email: "",
    fullName: "",
    username: "",
    roles: [],
    whenCreated: "",
  };
  const editMode = userId !== "new";
  const [isFetching, setIsFetching] = useState(editMode);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [isMerchantRedirect, setIsMerchantRedirect] = useState(false);

  const [fecthUserInfo, setFecthUserInfo] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showNewPassModal, setShowNewPassModal] = useState(null);
  const [showShareLoginDataModal, setShowShareLoginDataModal] = useState(null);
  const [userIdCreated, setUserIdCreated] = useState("");
  const [userInfo, setUserInfo] = useState({ ...defaultUserInfo });
  const [invalidFields, setInvalidFields] = useState([]);


  const [oldUserInfo, setOldUserInfo] = useState({
    ...defaultUserInfo,
  });
  const [deleteUsertId, setDeleteUserId] = useState(null);

  const emailChanged = userInfo.email !== oldUserInfo.email;

  const getUserInfo = useCallback(async (userId) => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/users/${userId}`,
        null
      );
      if (status === 200 || status === 201) {
        const { data } = response;

        setUserInfo(data);
        setOldUserInfo(data);

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Failed to fetch sellers info:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getUserRoles = useCallback(async (userId) => {
    setIsFetching(true);

    // setTimeout(() => {
    //   setRoles(['user', 'admin'])
    //   userId !== "new" ? setFecthUserInfo(true) : setIsFetching(false);
    // }, 2000);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/user/roles`,
        null
      );
      if (status === 200 || status === 201) {
        const { data } = response;

        setRoles(data);
        // setRoles(['user', 'admin']);
        //setIsFetching(false);
        userId !== "new" ? setFecthUserInfo(true) : setIsFetching(false);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch sellers info:", error);
    }
  }, []);

  useEffect(() => {
    const fetch = userId !== "new";
    setIsFetching(fetch);
  }, [userId]);

  useEffect(() => {
    if (fecthUserInfo) {
      setFecthUserInfo(false);
      getUserInfo(userId)
    }
  }, [fecthUserInfo, userId]);

  useEffect(() => {
    getUserRoles(userId);
  }, [userId]);

  const updateUser = async () => {
    setIsFetching(true);

    const invalidFields = validateInput();

    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);

    const params = {
      fullName: userInfo.fullName,
      email: userInfo.email,
      roles: userInfo.roles,
      username: userInfo.username,
    };

    try {
      if (!invalidFields) {
        const { status, response } = await apiRequest(
          "put",
          `/sap/api/v1/users/${userId}`,
          params
        );
        if (status === 200 || status === 201) {
          enqueueSnackbar(t("User_successfully_updated"), {
            variant: "success",
          });
          // setRedirect(true);

          if (response.data.result === "success") {
            setOldUserInfo({
              ...userInfo,
            })
          } else {
            const errorMessage = `${t("Error_200_start_message")} ${response.data.result} ${t("Error_200_end_message")}`;
            enqueueSnackbar(errorMessage, { variant: "error" });
          }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Failed to update merchant:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteUser = async () => {
    setIsDeleting(true);
    const params = {};

    try {
      const { status, response } = await apiRequest(
        "delete",
        `/sap/api/v1/users/${userId || deleteUsertId}`,
        params
      );

      if (status === 200 || status === 201) {
        if (response.data.result === "success") {
          setDeleteUserId(null);
          setRedirect(true);
          enqueueSnackbar(t("User_successfully_deleted"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(
            t("Error_200_start_message") +
            response.data.result +
            t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }
      } else if (status === 204) {

        enqueueSnackbar(t("User_successfully_deleted"), {
          variant: "success",
        });

        setRedirect(true);

      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to delete merchant:", error);
    } finally {
      setDeleteUserId(null);
      setIsDeleting(false);
    }
  };

  const validateInput = () => {
    const result = [];

    VALIDATION.ADD_EDIT_USER.forEach((field) => {
      if (field === "roles") {
        userInfo.roles.length === 0 && result.push(field);
      } else if (field === "email") {
        (!userInfo[field] || !isEmailValid(userInfo[field])) &&
          result.push(field);
      } else {
        !userInfo[field] && result.push(field);
      }
    });
    return result.length > 0 ? result : null;
  };

  const createSeller = async () => {
    const invalidFields = validateInput();
    setInvalidFields(invalidFields);

    if (invalidFields) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsFetching(false);
      return;
    }

    setIsFetching(true);


    const params = {
      fullName: userInfo.fullName,
      email: userInfo.email,
      roles: userInfo.roles,
      username: userInfo.username,
    };

    try {
      const { status, response } = await apiRequest(
        "post",
        `/sap/api/v1/users`,
        params
      );

      if (status === 200 || status === 201) {


        if (response.data.result === "success" && response.data.userId) {

          response.data?.userId && setUserIdCreated(response.data.userId);
          enqueueSnackbar(t("User_successfully_added"), {
            variant: "success",
          });

          setTimeout(() => {
            setIsMerchantRedirect(true);
          }, 500);


        } else if (response.data.result === "user_already_exists") {
          enqueueSnackbar(t("User_already_exists"), { variant: "error" });
        } else {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }


      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_user"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to create merchant:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const onChange = (state, value) => {

    const data = {
      ...userInfo,
      [state]: value,
    };

    setUserInfo(data);
  };

  const renderDeleteModal = () => {
    return (
      <Dialog
        open={!!deleteUsertId}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteUserId(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Confirm_user_delete")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Confirm_user_delete_description")} #{deleteUsertId}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => (isDeleting ? {} : deleteUser())}
              label={t("Delete")}
              variant="delete"
              disabled={isDeleting}
              className={cn({
                [styles.disabled_btn]: isDeleting,
              })}
              // iconPosition="off"
              icon={
                isDeleting && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            // iconOnly={isDeleting}
            // id={"DELETE_BUTTON"}
            />
            <Button
              onClick={() => setDeleteUserId(null)}
              variant="ghost"
              label={t("Cancel")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const navBarTitle = oldUserInfo.fullName || "";
  const disabledButton =
    JSON.stringify(userInfo) === JSON.stringify(oldUserInfo);

  const breadcrumbs = {
    step1Label: t("Users"),
    step1Url: "/app/users",
    step2Label: `${oldUserInfo.fullName || t("Add_new_user")}`,
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const passwordUser = async () => {
    setShowNewPassModal(false);
    try {
      const { status } = await apiRequest(
        "post",
        `/sap/api/v1/users/${userId}/password`,
        null
      );
      if (status === 200 || status === 201 || status === 204) {
        enqueueSnackbar((t("New_pass_generate_message_success_part_one") + userInfo.fullName + t("New_pass_generate_message_success_part_two")), {
          variant: "success",
        });
        setShowNewPassModal(false);
        setTimeout(() => {
          setRedirect(false);
        }, 2000);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const loginDataSeller = async () => {
    setShowShareLoginDataModal(false)
    try {
      const { status } = await apiRequest(
        "post",
        `/sap/api/v1/users/${userId}/credentials`,
        null
      );
      if (status === 200 || status === 201 || status === 204) {
        enqueueSnackbar((t("Login_data_sended_message_success_part_one") + userInfo.fullName + t("Login_data_sended_message_success_part_two")), {
          variant: "success",
        });
        setShowShareLoginDataModal(false)
        setTimeout(() => {
          setRedirect(false);
        }, 2000);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const renderNewPassModal = () => {
    return (
      <Dialog
        open={showNewPassModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowNewPassModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Generate_new_password")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Generate_new_password_text_user")} {"(" + userInfo.fullName + ")"}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => passwordUser()}
              label={t("Confirm_btn")}
              variant={"ghost"}
              disabled={isFetching}
              className={cn({
                [styles.disabled_btn]: isFetching,
                [`${styles[`btn_change`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isFetching && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            />
            <Button
              onClick={() => setShowNewPassModal(null)}
              variant="ghost"
              label={t("Canceled_decision")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const renderShareLoginDataModal = () => {
    return (
      <Dialog
        open={showShareLoginDataModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowShareLoginDataModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Share_login_data_title")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Share_login_data_text_for_user")} {userInfo["email"]}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => loginDataSeller()}
              label={t("Send")}
              variant={"ghost"}
              disabled={isFetching}
              className={cn({
                [styles.disabled_btn]: isFetching,
                [`${styles[`btn_change`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isFetching && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            />
            <Button
              onClick={() => setShowShareLoginDataModal(null)}
              variant="ghost"
              label={t("Cancel")}
            // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const changeUserRole = (roleName) => {
    removeError("roles")
    const arr: string[] = [];
    const findIndex = userInfo.roles.findIndex(
      (role) => role === roleName
    );

    if (findIndex < 0) {
      arr.push(...userInfo.roles, roleName);
    } else {
      const newArr = [
        ...userInfo.roles.slice(0, findIndex),
        ...userInfo.roles.slice(findIndex + 1),
      ];
      arr.push(...newArr);
    }

    setUserInfo({
      ...userInfo,
      roles: [...arr]
    })
  }

  const renderRoles = () => {
    const content = roles.map((role, index) => {
      return (
        <FormControlLabel
          value={role}
          control={<Checkbox onChange={() => changeUserRole(role)} />}
          key={index}
          label={role}
          checked={userInfo.roles.findIndex(userRole => userRole === role) > -1}
          labelPlacement={role}

        />
      )
    })

    return content
  }

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      {renderDeleteModal()}

      {renderNewPassModal()}
      {renderShareLoginDataModal()}
      <div>
        {redirect && <Redirect to={"/app/users/"} />}
        {isMerchantRedirect && (
          <Redirect to={`/app/users/${userIdCreated}`} />
        )}
        <div
          className={cn({
            [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.forms_wrapper}>
                <div className={styles.info_block}>
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                    {userInfo.whenCreated && (
                      <span className={styles.creation_date}>
                        {t("Creation_date")}:{" "}
                        {moment(userInfo.whenCreated).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    )}
                  </div>
                  <div className={styles.info_block_content}>
                    {editMode && (
                      <>
                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label>ID</label>
                          <div className={styles.block_value}>#{userId}</div>
                        </div>
                      </>
                    )}
                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantName">{t("Full_Name")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Full_Name")}
                        type={"text"}
                        id="fullName"
                        value={userInfo.fullName}
                        onFocus={() => removeError("fullName")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "fullName") >
                          -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "fullName") > -1
                        }
                        onChange={(e) => onChange("fullName", e.target.value)}
                      />
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                        [styles.roles_container]: true,
                      })}
                    >
                      <label htmlFor="storeId">{t("Roles")}</label>
                      <FormControl fullWidth>
                        {renderRoles()}
                        {
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "roles") >
                          -1 &&
                          <div className={styles.required_field}>{t("Required_field")}</div>
                        }
                      </FormControl>


                    </div>


                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="email">{t("Email")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Email")}
                        type={"text"}
                        id="email"
                        value={userInfo.email}
                        onFocus={() => removeError("email")}
                        helperText={
                          invalidFields?.length > 0 &&
                            invalidFields?.findIndex((i) => i === "email") > -1 &&
                            !userInfo.email
                            ? t("Required_field")
                            : !isEmailValid(userInfo.email)
                              ? userInfo.email.length > 0 &&
                              invalidFields?.findIndex((i) => i === "email") >
                              -1 &&
                              t("Email_is_incorrent")
                              : invalidFields?.findIndex((i) => i === "email") >
                              -1 && t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "email") > -1
                        }
                        onChange={(e) => onChange("email", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div>


                  <div
                    className={cn({
                      [styles.info_block]: true,
                      [styles.info_block_login]: true,
                    })}
                  >
                    <div className={styles.info_block_title}>
                      <span>{t("Credentials")}</span>
                    </div>
                    <div className={styles.info_block_content}>
                      <div
                        className={cn({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label htmlFor="fullName">{t("Login")}</label>
                        <TextField
                          variant="outlined"
                          label={t("Login")}
                          type={"text"}
                          id="fullName"
                          value={userInfo.username}
                          onFocus={() => removeError("username")}
                          helperText={
                            invalidFields?.length > 0 &&
                            invalidFields.findIndex((i) => i === "username") >
                            -1 &&
                            t("Required_field")
                          }
                          error={
                            invalidFields?.length > 0 &&
                            invalidFields.findIndex((i) => i === "username") > -1
                          }
                          onChange={(e) => onChange("username", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </>
          )}
        </div>

        {!isFetching && (
          <div className={styles.actions_buttons}>
            <div>
              {editMode && (
                <>
                  <Button
                    // icon={<SaveIcon />}
                    label={t("Generate_new_password")}
                    variant="primary"
                    className={styles.new_pass_btn}
                    onClick={() => setShowNewPassModal(true)}
                    disabled={emailChanged || isFetching}
                  />
                  <Button
                    // icon={<SaveIcon />}
                    label={t("Share")}
                    variant="primary"
                    className={styles.new_pass_btn}
                    onClick={() => setShowShareLoginDataModal(true)}
                    disabled={emailChanged || isFetching}
                  />

                  <Button
                    icon={<DeleteIcon onClick={() => { }} />}
                    label={t("Delete")}
                    variant={"delete"}
                    onClick={() => setDeleteUserId(userId)}
                  />
                </>
              )}
            </div>
            <div>
              {userId !== "new" ?
                <Button
                  variant="ghost"
                  className={styles.reset_button}
                  disabled={disabledButton}
                  label={t("Reset")}
                  onClick={() => setUserInfo(oldUserInfo)}
                  icon={<ResetIcon />}
                />
                : (
                  <Link to="/app/sellers/" className={styles.no_underline}>
                    <Button
                      className={styles.reset_button}
                      variant="ghost"
                      icon={<ResetIcon />}
                      // disabled={disabled}
                      label={t("Cancel")}
                    />
                  </Link>
                )}
              <Button
                icon={<SaveIcon />}
                label={t("Save")}
                variant="primary"
                onClick={() =>
                  userId === "new"
                    ? createSeller()
                    : disabledButton
                      ? {}
                      : updateUser()
                }
                disabled={disabledButton || isFetching}
              />
            </div>
          </div>
        )}
      </div>
    </AppMainLayout>
  );
};

export default UsersDetails;
